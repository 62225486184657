















































































import {
  defineComponent,
  computed,
  ref,
  PropType,
} from '@vue/composition-api';
import { INewWorkOrderTemplate } from '@/types';
import { getFieldsFromTemplate } from '@/utils';

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    template: {
      type: Object as PropType<INewWorkOrderTemplate>,
      required: true,
    },
    type: {
      type: String as PropType<'template' | 'section' | 'field' | 'error'>,
      required: true,
    },
  },
  emits: ['input', 'added:variable'],
  setup(props, { emit }) {
    const variable = ref('');
    const templateVars = ref([
      { value: 'identifier' },
      { value: 'version' },
      { value: 'id' },
    ]);

    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
    };

    const open = computed({
      get: () => props.value,
      set: (val) => emit('input', val),
    });

    /**
     *  @summary Sets the title property for the popup based on the type of the variable being added
     *  @author Jack O'Connor
     */
    const title = computed(() => {
      let vartitle;
      if (props.type === 'field') {
        vartitle = 'custom field';
      } else if (props.type === 'section') {
        vartitle = 'section name';
      } else if (props.type === 'template') {
        vartitle = 'template property';
      }

      return `${vartitle}`;
    });

    /**
     *  @summary Gets all fields attached to a work order template and returns them in one array
     *  @author Jack O'Connor
     */
    const fields = computed(() => {
      let fieldArr: any[] = [];
      if (props.template) {
        fieldArr = getFieldsFromTemplate(props.template);
      }

      return fieldArr;
    });

    /**
     *  @summary Creates an array of objects with section id and value, for populating the section dropdown
     *  @author Jack O'Connor
     */
    const sections = computed(() => {
      const sectionArr = [];
      for (let index = 0; index < props.template.sections.length; index++) {
        sectionArr.push({
          id: props.template.sections[index].id,
          value: props.template.sections[index].name,
        });
      }

      return sectionArr;
    });

    /**
     *  @summary Formats the template variable based on the type of the popup that has been opened.
     *  @author Jack O'Connor
     */
    const addVariable = () => {
      let varVal;
      if (props.type === 'template') {
        varVal = `{{$wot_${variable.value}}}`;
      } else if (props.type === 'section') {
        varVal = `{{$sid_${variable.value}}}`;
      } else if (props.type === 'field') {
        varVal = `{{$cfid_${variable.value}}}`;
      }
      emit('added:variable', varVal);
      open.value = false;
    };

    return {
      variable,
      rules,
      fields,
      open,
      title,
      sections,
      templateVars,
      addVariable,
    };
  },
});
