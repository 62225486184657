var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vue-richtext-editor"},[_c('mavon-editor',{attrs:{"language":"en","toolbars":_vm.config,"editable":!_vm.disabled,"placeholder":_vm.disabled ? 'Select a template before editing' : 'Type message...'},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}},[_c('template',{slot:"left-toolbar-after"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-1 px-0",attrs:{"aria-hidden":"true","title":"custom","height":"30px","x-small":"","color":"white","elevation":"0"},on:{"click":function($event){return _vm.$emit('custom', 'template')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-document-multiple ")])],1)]}}])},[_c('span',[_vm._v("Add Template Variables")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-1 px-0",attrs:{"aria-hidden":"true","title":"custom","height":"30px","x-small":"","color":"white","elevation":"0"},on:{"click":function($event){return _vm.$emit('custom', 'section')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-fountain-pen ")])],1)]}}])},[_c('span',[_vm._v("Add Section Variable")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-1 px-0",attrs:{"aria-hidden":"true","title":"custom","height":"30px","x-small":"","color":"white","elevation":"0"},on:{"click":function($event){return _vm.$emit('custom', 'field')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-vector-polyline-edit ")])],1)]}}])},[_c('span',[_vm._v("Add Field Variables")])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }