























































































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'RichTextField',
  props: {
    value: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    //  allowed tags are a, br, p, ol, ul, li, strong? em?
    const config = {
      bold: false,
      italic: false,
      link: true,
      ul: true,
      ol: true,
      preview: true,
      subfield: true,
      fullscreen: true,
    };

    /**
     *  Getter setter computed prop for getting and setting the model value prop parsed.
     */
    const inputVal = computed({
      get: () => props.value ? props.value : '',
      set: (val) => {
        emit('input', val);
      },
    });

    return {
      inputVal,
      config,
    };
  },
});
