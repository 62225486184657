











































































































import { defineComponent, computed, ref } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { INewWorkOrderTemplate } from '@/types';
import RichTextField from '@/components/fields/RichTextField.vue';
import AddVariablePopup from '@/components/MessageTemplates/popup/AddTemplateVariable.vue';

interface IMessageTemplatePayload {
  id?: string
  name: string
  subject?: string
  recipient: string
  message: string
  workOrderTemplateId: string
}

export default defineComponent({
  name: 'MessageTemplateNewMail',
  components: {
    RichTextField,
    AddVariablePopup,
  },
  emits: ['input'],
  setup() {
    const templateName = ref('');
    const subject = ref('');
    const recipient = ref('');
    const message = ref('');
    const templateId = ref('');
    const popupOpen = ref(false);
    const popupType = ref('');
    const id = ref(router.app.$route.params?.id);
    const templateType = ref(router.app.$route.params?.type);
    const formLoading = ref(false);
    const templateLoading = ref(false);
    const isLoading = ref(false);

    const rules = {
      required: (value: string): boolean | string => !!value || 'This field is required',
    };

    const templates = computed(() => store.getters['new/template/getWorkOrderTemplates']);
    const selectedTemplate = computed(() => templates.value.find((tmp: INewWorkOrderTemplate) => tmp.id === templateId.value));
    const existingTemplate = computed(() => store.getters['new/messageTemplate/getMessageTemplateById'](templateType.value, id.value));

    /**
     *  @summary Opens the variable popup and sets the popup type based on which button was clicked in rich text
     *  @author Jack O'Connor
     */
    const openPopup = (type: string) => {
      if (!selectedTemplate.value) {
        popupType.value = 'error';
      } else {
        popupType.value = type;
      }
      popupOpen.value = true;
    };

    /**
     *  @summary Adds an emitted variable to the message string.
     *  @author Jack O'Connor
     */
    const addVariable = (variable: string) => {
      message.value += variable;
      popupType.value = '';
    };

    /**
     *  @summary Calls the store action to create the message template with the given data.
     *  @author Jack O'Connor
     */
    const createTemplate = () => {
      isLoading.value = true;
      const data: IMessageTemplatePayload = {
        name: templateName.value,
        recipient: recipient.value,
        message: message.value,
        workOrderTemplateId: selectedTemplate.value.id,
      };

      if (templateType.value === 'mail') {
        data.subject = subject.value;
      }

      store.dispatch('new/messageTemplate/createMessageTemplate', {
        data,
        type: templateType.value,
      })
        .finally(() => {
          isLoading.value = false;
          router.push({
            name: 'MessageTemplates',
            params: {
              tab: templateType.value,
            },
          });
        });
    };

    /**
     *  @summary Edits an existing template. Checks to ensure fields have changed before calling the API.
     *  @author Jack O'Connor
     */
    const editTemplate = async () => {
      isLoading.value = true;

      const data: IMessageTemplatePayload = {
        id: id.value,
        workOrderTemplateId: selectedTemplate.value.id,
        name: templateName.value,
        recipient: recipient.value,
        message: message.value,
      };

      if (templateType.value === 'mail') {
        data.subject = subject.value;
      }

      if (templateName.value === existingTemplate.value.name && recipient.value === existingTemplate.value.recipient && ((templateType.value === 'mail' && subject.value === existingTemplate.value.subject) || templateType.value === 'sms') && message.value === existingTemplate.value.message) {
        store.dispatch('alerts/createAlert', {
          type: 'warning',
          message: 'There are no changes to save',
        });
      } else {
        await store.dispatch('new/messageTemplate/updateMessageTemplate', {
          data,
          type: templateType.value,
        });
      }

      isLoading.value = false;
    };

    // If no templates exist we will call the API to get them.
    if (!templates.value || (templates.value && templates.value.length === 0)) {
      templateLoading.value = true;

      store.dispatch('new/template/fetchWorkOrderTemplates', { 'filter[published]': true })
        .finally(() => {
          templateLoading.value = false;
        });
    }

    /**
     *  @summary Prepopulates the form with DB data if an ID param is parsed in the URL and exists in the DB
     *  @author Jack O'Connor
     */
    const populateExistingTemplate = async () => {
      formLoading.value = true;
      if (!existingTemplate.value) {
        await store.dispatch('new/messageTemplate/readMessageTemplate', {
          type: templateType.value,
          id: id.value,
        });
      }

      templateName.value = existingTemplate.value.name;
      subject.value = existingTemplate.value.subject;
      recipient.value = existingTemplate.value.recipient;
      message.value = existingTemplate.value.message;
      templateId.value = existingTemplate.value.workOrderTemplateId;

      formLoading.value = false;
    };

    if (id.value) {
      populateExistingTemplate();
    }

    return {
      templateName,
      subject,
      recipient,
      message,
      templateId,
      isLoading,
      templateLoading,
      selectedTemplate,
      popupType,
      popupOpen,
      rules,
      templates,
      id,
      templateType,
      openPopup,
      formLoading,
      addVariable,
      createTemplate,
      editTemplate,
    };
  },
});
